#e-library-web {
	.trigger {
		padding: 0 24px;
		font-size: 18px;
		line-height: 64px;
		cursor: pointer;
		transition: color 0.3s;
		&:hover {
			color: #1890ff;
		}
	}
	.sider-layout-sidebar {
		overflow: auto;
		height: 100vh;
		position: fixed;
		left: 0;
	}
	.logo {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 15px;

		img {
			height: 48px;
		}
	}
	.site-layout {
		margin-left: 200px;
	}
	.site-layout-true {
		margin-left: 82px;
	}

	.site-layout .site-layout-background {
		// background: #fff;
		&.content {
			min-height: calc(100vh - 112px);
			overflow: initial;
			max-width: 1200px;
			padding: 0 15px;
			width: 100%;
			margin: 16px auto;
		}
	}

	.site-layout-true .site-layout-background {
		// background: #fff;
		&.content {
			min-height: calc(100vh - 112px);
			overflow: initial;
			max-width: 1200px;
			width: 100%;
			margin: 16px auto;
			padding: 0 10px;
		}
	}
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		button {
			margin-right: 24px;
		}
	}
}
