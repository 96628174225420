@import '~antd/dist/antd.css';
.flex{
  display: flex;
}
.justify-center{
  justify-content: center;
}
.justify-evenly{
  justify-content: space-evenly;
}
.flex-col{
  flex-flow: column;
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}